import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={28} height={28} viewBox="0 0 16 16" {...props}>
    <title>Facebook</title>
      <path
        fill="#fff"
        d="M7.2 16C2.8 15.5-.4 11.6.1 7.2S4.5-.4 8.9.1s7.6 4.4 7.1 8.8c-.4 3.6-3.2 6.5-6.8 7.1v-5.1h2.2v-2H9.2v-.2c0-2.3.7-2.7 2.6-2.7V4c-2.1 0-4.6.5-4.6 4.7v.2H5v2h2.2V16z"
      />
    </svg>
  )
}

export default SvgComponent
