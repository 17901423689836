export default {
	tel:'080-1476-6674',
	mail:'41motoki@gmail.com',
	address1:'京都市北区小山西大野町38-2',
	address2:'（北大路新町西入ル北側）',
	zipcode:'〒603-8167',
	closed:'月・火　定休',
	openHours1:'水〜金　19：00～25：00',
	openHours2:'土・日　16：00～25：00',
	openHoursNotice:'24時最終入店',
	sakabaFB:'https://www.facebook.com/sakabamotoki/',
	sakayaFB:'https://www.facebook.com/sakayamotoki/',
}

// 	//	deviceChecker PCSP・UA判定とクラスの付与
// 	const deviceChecker = () => {
// 		var ua = navigator.userAgent.toLowerCase();
// 		var name = "";
// 		// SP判定
// 		var isAndroid      = ua.indexOf('android') !== -1;
// 		if(isAndroid){ name = 'isAndroid'; }

// 		var isWindowsPhone = ua.indexOf('windows phone') !== -1;
// 		if(isWindowsPhone){ name = 'isWindowsPhone'; }
// 		var isBlackBerry   = ua.indexOf('blackberry') !== -1;
// 		if(isBlackBerry){ name = 'isBlackBerry'; }
// 		var isIPhone       = ua.indexOf('iphone') !== -1;
// 		if(isIPhone){ name = 'isIPhone'; }
// 		var isIPad         = ua.indexOf('ipad') !== -1;
// 		if(isIPad){ name = 'isIPad'; }
// 		var isIPod         = ua.indexOf('ipod') !== -1;
// 		if(isIPod){ name = 'isIPod'; }


// 		var isIOS          = (isIPhone || isIPad || isIPod);
// 		if(isIOS){
// 		 var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
//           var versions = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
//           if(versions[0] < 10 && versions[0] > -1){
//           	isLegacy = true;
//           }
// 		}


// 		var isSP  = isAndroid || isIOS || isWindowsPhone || isBlackBerry;
// 		return isSP;

// };
// // 		// スマホORデスクトップ確認
// // export const uachecker = {
// // 		 checkMobile(){
// // 			return isSP;
// // 		},
// // 	 // ブラウザ名を取得
// // 	 getName = function(){
// // 			return name;
// // 		},
// // };


