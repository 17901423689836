//components/image.js
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import Img from "gatsby-image/withIEPolyfill"
// import Img from "gatsby-image/withIEPolyfill"


const Image = ({filename,...props}) => {

const data = useStaticQuery(graphql`
  query {
    placeholderImage: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(
              maxWidth:3000
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`);

  const image = data.placeholderImage.edges.find(n => {
    return n.node.relativePath.includes(filename);
  });
  if (!image) { return null; }

  return <Img fluid={image.node.childImageSharp.fluid} {...props} />
}

export default Image