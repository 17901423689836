/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import info from '../config/config'

//googleFonts
import "typeface-cormorant-garamond";
import "typeface-sawarabi-mincho";
import "typeface-montserrat";
// import Header from "./header"
import "./layout.css"
import "./css/style.scss"

import IcoFB from "./icons/fb"

const Layout = ({ children,type }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <main className={type}>
        <noscript>
          <dl id="browserMsg">
            <dt>JavaScriptの設定を有効にしてください。</dt>
            <dd>お使いのブラウザはJavaScriptをサポートしていないか、JavaScriptの設定が無効になっています。<br/>
            このサイトでは、JavaScriptを使用しています。すべての機能をご利用希望の方は、JavaScriptを有効にしてください。</dd>
          </dl>
        </noscript>
        {children}
        </main>
        <footer id="footer">
        {/* <p className="cBtnTopSp"><a className="jsScroll" href="#wrapper">ページの先頭へ↑</a></p> */}
        <div className="inner">
          <dl className="ftLinks">
            <dt>お問い合わせ :</dt>
            <dd><a className="cAlpha" href={`tel:${info.tel}`}><span>{info.tel}</span></a></dd>
            <dd><a className="cAlpha" href={`mailto:${info.mail}`}><span>{info.mail}</span></a></dd>
            <dd className="sns"><a className="cAlpha" href={info.sakabaFB} aria-label="酒場元木 Facebook" target="_blank" rel="noreferrer">
              <IcoFB/>
            </a></dd>
          </dl>
          <p id="copyright">
            &copy; {new Date().getFullYear()} Sakaba Motoki All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
}
Layout.defaultProps = {
  type: ``,
}
export default Layout
