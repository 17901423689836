import React from "react"
import IcoArr from "./icons/arr"
import IcoWin from "./icons/win"
// import styled from "styled-components"
// import "./_mixin.scss"

export default class Button extends React.Component {
  render() {
    return (
    <button className={(this.props.icon === "back")?`cBtn1 back`:`cBtn1`} type="button">
      {this.props.children}
      {(this.props.icon === "blank")?
      <IcoWin className="icoWin"/>
      :
      <IcoArr className="icoArr"/>
      }
    </button>
    )
  }
}

